import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserData } from 'src/app/core/models/user/user';
import { noDiscordLinks, validUrl } from 'src/app/shared/utils/helpers';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-edit-profile-about-modal',
  templateUrl: './edit-profile-about-modal.component.html',
  styleUrls: ['./edit-profile-about-modal.component.scss'],
})
export class EditProfileAboutModalComponent implements OnInit {
  @Input() user: UserData;
  @Input() isMobile: boolean;
  @ViewChild('confirmCloseModal', { static: false })
  confirmCloseModal: TemplateRef<any>;
  public form: FormGroup;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      instagramLink: [this.user?.profile?.instagramLink || '', validUrl],
      twitterLink: [this.user?.profile?.twitterLink || '', validUrl],
      websiteLink: [this.user?.profile?.websiteLink || '', validUrl],
      about: [this.user?.profile?.about || '', [Validators.maxLength(2000), noDiscordLinks]],
    });
  }

  public close(): void {
    if (this.form.dirty) {
      this.modalService.notify(this.confirmCloseModal, 370, 250);
    } else {
      this.confirmClose()
    }
  }

  public confirmClose(){
    this.modalService.close();
    this.modalController.dismiss();
  }

  public edit(): void {
    this.modalController.dismiss({
      instagramLink: this.form.get('instagramLink').value,
      twitterLink: this.form.get('twitterLink').value,
      websiteLink: this.form.get('websiteLink').value,
      about: this.form.get('about').value,
    });
  }
}
