import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { UserModel } from '../../../core/models/user/user';
import { AppQuery } from 'src/app/core/state/app/app.query';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ScreenService } from 'src/app/core/services/screen.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  navPages: { title: string; url: string }[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Trading Apps',
      url: '/trade',
    },
    {
      title: 'Copy Trading',
      url: '/copytrading',
    },
    {
      title: 'Dashboard',
      url: '/dashboard',
    },
  ];
  public dashboardPath: string = '/dashboard';
  public user: UserModel;
  public isMobile: boolean;
  @Input() activeLink: string;
  @ViewChild('confirmOnboarding', { static: false })
  confirmOnboarding: TemplateRef<any>;
  subscription: Subscription = new Subscription();
  plan: any;
  activePlan: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appQuery: AppQuery,
    public profileService: ProfileService,
    private screenService: ScreenService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.screenService.isMobileView;
    this.subscription.add(
      this.screenService.$isMobileSubject.subscribe((data: boolean) => {
        this.isMobile = data;
      })
    );
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe((user) => {
          this.user = user;
        })
    );
  }

  navigate(url: string = ''): void {
    this.router.navigate([`/${url}`]);
  }

  async signInWithDiscord(): Promise<void> {
    try {
      await this.authService.signInDiscord();
    } catch (err) {
      console.log(err);
    }
  }

  public logout(): void {
    this.authService.logout();
    if (location.href.includes('dashboard')) {
      this.router.navigate(['/']);
    } else {
      window.location.reload();
    }
  }

  public navigateDashboard(path: string = ''): void {
    if (path !== this.dashboardPath) {
      return;
    }

    if (this.user) {
      this.router.navigate([this.dashboardPath]);
    } else {
      this.signInWithDiscord();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
