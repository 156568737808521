import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription, combineLatest, filter } from 'rxjs';

import { AuthModalComponent } from './shared/components/modals/auth-modal/auth-modal.component';
import { ModalService } from './shared/components/modals/modal/modal.service';
import { AppQuery } from './core/state/app/app.query';
import { UserModel } from './core/models/user/user';
import { AuthService } from './core/services/auth.service';
import { ScreenService } from './core/services/screen.service';
import { SignalRService } from './core/services/signal-r.service';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from './shared/utils/constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public hiddenBurgerMenu: boolean;
  public isMobile: boolean;
  public activeRoute: string;
  private modal: HTMLIonModalElement = null;
  private user: UserModel;
  @ViewChild('confirmOnboarding', { static: false })
  private modalCompleteOnboarding: TemplateRef<any>;
  private subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private modalController: ModalController,
    private modalService: ModalService,
    private router: Router,
    private appQuery: AppQuery,
    private screenService: ScreenService,
    private signalRService: SignalRService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.isMobile = this.screenService.isMobileView;
    this.subscription.add(
      this.screenService.$isMobileSubject.subscribe((data: boolean) => {
        this.isMobile = data;
      })
    );
    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ),
      this.appQuery.select((x) => x.currentUser),
    ]).subscribe(async ([event, user]: [NavigationEnd, any]) => {
      this.hiddenBurgerMenu =
        event.url.includes('dashboard') || event.url.includes('profile');
      this.activeRoute = event.url;
      this.showAiChatBot(event.url);
    });
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe(async (user) => {
          if (user) {
            this.user = user;
            if (!this.user.data.hasCompletedOnboarding && !this.syncPage()) {
              await this.openOnboardingModal();
            }
            this.signalRService.initSignalR();
          }
        })
    );
    this.authService.initUser();
    this.hiddenBurgerMenu =
      window.location.href.includes('dashboard') ||
      window.location.href.includes('profile');
  }

  async openOnboardingModal(): Promise<void> {
    this.modal = await this.modalController.create({
      component: AuthModalComponent,
      componentProps: {
        modalInstance: this.modal,
        canDismiss: true,
      },
      backdropDismiss: true,
      canDismiss: true,
      cssClass: 'auto-height',
    });
    this.modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned?.data?.data === 'completeOnboarding') {
        this.modalService.notify(this.modalCompleteOnboarding, 370, 368);
      }
    });
    await this.modal.present();
  }

  public proceedModal(): void {
    this.modalService.close();
    this.router.navigate(['/membership']);
  }

  public onAppWidthMonitorChange(data) {
    if (data && data <= TABLET_BREAKPOINT) {
      this.screenService.isTabletView = true;
    } else {
      this.screenService.isTabletView = false;
    }
    if (data && data <= MOBILE_BREAKPOINT) {
      this.screenService.isMobileView = true;
    } else {
      this.screenService.isMobileView = false;
    }
    this.screenService.$isMobileSubject.next(this.screenService.isMobileView);
    this.screenService.$isTabletSubject.next(data && data <= TABLET_BREAKPOINT);
  }

  private syncPage(): boolean {
    return window.location.href.includes('sync');
  }

  private showAiChatBot(path: string): void {
    const forbiddenPages = ['/dashboard', '/profile'];
    const existingChatbot = document.querySelector(
      'zapier-interfaces-chatbot-embed'
    );
    const existingScript = document.querySelector(
      "script[src*='zapier-interfaces']"
    );
    if (forbiddenPages.some((x) => path.includes(x))) {
      if (existingChatbot) {
        existingChatbot.remove();
      }
      if (existingScript) {
        existingScript.remove();
      }
    } else if (!existingChatbot) {
      const script = document.createElement('script');
      script.async = true;
      script.type = 'module';
      script.src =
        'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js';
      document.head.appendChild(script);
      const chatbot = document.createElement('zapier-interfaces-chatbot-embed');
      chatbot.setAttribute('is-popup', 'true');
      chatbot.setAttribute('chatbot-id', 'cm7m4pqsg000a125x6ycj27va');
      document.body.appendChild(chatbot);
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
