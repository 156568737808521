import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  public mobileBreakpoint = 1000;
  public isMobileView = window.innerWidth <= this.mobileBreakpoint;
  public $isMobileSubject = new Subject<boolean>();

  constructor() {
    this.$isMobileSubject.next(window.innerWidth <= this.mobileBreakpoint);
  }
}
