import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExchangeType } from 'src/app/core/enums/exchange-missions';
import { UserMissionsModel } from 'src/app/core/models/user/user-missions';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-missions',
  templateUrl: './missions.component.html',
  styleUrls: ['./missions.component.scss'],
})
export class MissionsModalComponent implements OnInit {
  public steps: {
    label: string;
    color: string;
    step: number;
    checked: boolean;
  }[] = [
    {
      label: 'Use our recommended exchange to trade crypto effectively',
      color: 'linear-gradient(220deg, #76F3EB 2.36%, #47D28F 98.53%)',
      step: 1,
      checked: false,
    },
    {
      label: 'Set up Copy Trading Bot and follow the signals of our traders',
      color: 'linear-gradient(220deg, #35F4E8 0%, #0EC26B 100%)',
      step: 2,
      checked: false,
    },
    {
      label: 'Watch basic training material',
      color: 'linear-gradient(220deg, #0FDFEC 0%, #02BF4E 100%)',
      step: 3,
      checked: false,
    },
    {
      label: 'Watch advanced training material',
      color: 'linear-gradient(220deg, #0FECB7 0%, #02BF15 100%)',
      step: 4,
      checked: false,
    },
    {
      label: 'Take and pass CryptoTraders.com risk management test',
      color: 'linear-gradient(220deg, #05D659 0%, #00B412 100%)',
      step: 5,
      checked: false,
    },
    {
      label: 'Apply for funded trading account',
      color: 'linear-gradient(220deg, #98F980 0%, #7CB404 100%)',
      step: 6,
      checked: false,
    },
    {
      label: 'Take and pass the CryptoTraders.com technical analysis test',
      color: 'linear-gradient(220deg, #F9F480 0%, #C2B00B 100%)',
      step: 7,
      checked: false,
    },
  ];
  public loading: boolean;
  public exchanges: ExchangeType[] = [ExchangeType.bybit, ExchangeType.blofin];
  public selectedExchange: ExchangeType | null = null;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private toastService: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const userMisiions = await this.userService.getUserMissions();
    this.updateStepsState(userMisiions);
    this.loading = false;
  }

  public close(): void {
    this.modalController.dismiss();
  }

  public async updateMissionsData(): Promise<void> {
    const params: UserMissionsModel = {
      chosenExchange: this.selectedExchange,
      completedMissions: this.steps
        .filter((step) => step.checked)
        ?.map((x) => x.step),
    };
    try {
      await this.userService.updateUserMissions(params);
    } catch (err) {
      this.selectedExchange = null;
      this.toastService.presentToast('Error while saving missions', 'error');
    }
  }

  public chooseExchange(option: ExchangeType) {
    this.selectedExchange = option === this.selectedExchange ? null : option;
    this.steps[0].checked = !!this.selectedExchange;
    this.updateMissionsData();
  }

  private updateStepsState(data: UserMissionsModel): void {
    this.selectedExchange = data.chosenExchange;
    this.steps.forEach(
      (el) => (el.checked = data.completedMissions?.includes(el.step))
    );
    this.userService.getCurrentUser();
  }
}
