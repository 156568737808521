import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  public isMobileView = window.innerWidth <= MOBILE_BREAKPOINT;
  public isTabletView = window.innerWidth <= TABLET_BREAKPOINT;
  public $isMobileSubject = new Subject<boolean>();
  public $isTabletSubject = new Subject<boolean>();

  constructor() {
    this.$isMobileSubject.next(window.innerWidth <= MOBILE_BREAKPOINT);
    this.$isTabletSubject.next(window.innerWidth <= TABLET_BREAKPOINT);
  }
}
