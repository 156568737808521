import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appWidthMonitor]',
})
export class WidthMonitorDirective implements OnInit, OnDestroy {
  @Output('appWidthMonitor') widthChanged = new EventEmitter<number>();

  private element: HTMLElement;
  private width: number;
  private subscription = new Subscription();

  constructor(private elRef: ElementRef<HTMLElement>) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    this.subscription.add(
      fromEvent(window, 'resize')
        .pipe(throttleTime(200))
        .subscribe(() => this.checkWidth())
    );

    this.subscription.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(300))
        .subscribe(() => this.checkWidth())
    );

    this.checkWidth();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private checkWidth() {
    if (!this.element) {
      return;
    }
    const width = this.element.offsetWidth;
    if (width !== this.width && width !== 0) {
      this.width = width;
      this.widthChanged.emit(this.width);
    }
  }
}
