<app-overlay (click)="close()"></app-overlay>
<div class="modal ion-page" [class.disable-click]="loading">
  <div>
    <button class="close-btn" (click)="close()"></button>
    <div class="title">Missions</div>
    <div class="inner-content custom-scroll">
      <div class="wrap" [class.with-overlay]="loading">
        <div
          class="step pointer"
          [class.with-opacity]="!item.checked"
          [class.step--last]="i === steps.length - 1"
          *ngFor="let item of steps; let i = index"
        >
          <div class="dot" [style.background]="item.color"></div>
          <div class="count-checkbox-wrap">
            <div class="count">step {{ i + 1 }}</div>
            <div class="checkbox-wrapper">
              <div class="missions-modal-checkbox">
                <ion-checkbox
                  [class.disabled]="i === 0"
                  [(ngModel)]="item.checked"
                  (ionChange)="updateMissionsData()"
                ></ion-checkbox>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="label-wrap">
            <div class="label">{{ item.label }}</div>
            <ion-segment *ngIf="i === 0">
              <ion-segment-button
                *ngFor="let option of exchanges"
                (click)="chooseExchange(option)"
                [class.selected]="selectedExchange === option"
              >
                <div
                  class="segment-icon"
                  [style.background-image]="
                    'url(assets/modals/missions/' +
                    option.toLowerCase() +
                    (selectedExchange === option ? '-selected' : '') +
                    '.svg)'
                  "
                ></div>
              </ion-segment-button>
            </ion-segment>
            <app-separator
              [background]="'rgba(255, 255, 255, 0.1)'"
            ></app-separator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="spinner" *ngIf="loading">
  <img src="/assets/dashboard/spinner.webp" />
</div>
