import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { interval, Subject, Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HubConnection } from '@microsoft/signalr';
import { AlertModel } from '../models/alerts/alert';
import { AuthService } from './auth.service';
import { getCookie } from 'src/app/shared/utils/helpers';
import { AlertActionType } from '../enums/signal-r-alert-type';
import { Channels } from '../enums/signal-r-channels';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  newAlertAction$ = new Subject<AlertModel>();

  connected = false;

  private connection?: HubConnection;
  private subscription?: Subscription;

  async initSignalR(): Promise<void> {
    await this.closeConnection();
    this.subscription = new Subscription();
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${environment.apiBaseUrl}signalr`, {
        accessTokenFactory: () => getCookie(AuthService.AccessTokenCookieName),
      })
      .build();

    this.connection.start().then(() => {
      this.connected = true;
    });

    this.connection.onclose(() => {
      this.connected = false;
      this.subscription.add(
        timer(10000).subscribe(() => this.handleReconnect())
      );
    });

    this.handleMessages();
  }

  closeConnection(): Promise<void> {
    this.subscription?.unsubscribe();
    return this.connection?.stop();
  }

  handleMessages(): void {
    this.connection.on(
      Channels.alert,
      (data: { alert: AlertModel; type: AlertActionType }[]) => {
        this.newAlertAction$.next(data[0]?.alert);
      }
    );
  }

  handleReconnect() {
    this.subscription.add(
      interval(10000)
        .pipe(takeWhile(() => !this.connected))
        .subscribe(() => {
          this.connection.start().then(() => {
            this.connected = true;
          });
        })
    );
  }
}
