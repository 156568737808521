import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { UserData } from '../../../../core/models/user/user';
import { ToastService } from '../../../../core/services/toast.service';
import { AppQuery } from 'src/app/core/state/app/app.query';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnDestroy {
  public step: number = 1;
  public user: UserData;
  public formStep1: FormGroup;
  public inValidRiskData: boolean = false;
  public alreadyExistingEmail: boolean;
  public alreadyExistingName: boolean;
  public isLoading: boolean;
  private onboardingData: OnboardingDataModel = {
    displayName: '',
    email: '',
    hasCompletedOnboarding: false,
    preferredLeverage: '',
    preferredRiskType: '',
    userName: '',
  };
  private subscription: Subscription = new Subscription();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalController: ModalController,
    private toast: ToastService,
    private appQuery: AppQuery
  ) {
    this.formStep1 = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      discordName: ['', [Validators.required, Validators.minLength(2)]],
      name: ['', [Validators.required, Validators.minLength(2)]],
    });

    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe((user) => {
          this.user = user?.data;
          if (this.user) {
            this.formStep1.get('email').setValue(this.user?.email || '');
            this.formStep1
              .get('discordName')
              .setValue(this.user?.discordUserName || '');
            this.formStep1.get('name').setValue(this.user?.displayName || '');
          }
        })
    );
  }

  async continue(): Promise<void> {
    this.isLoading = true;
    if (this.formStep1.valid) {
      const formValues = this.formStep1.value;
      this.alreadyExistingEmail = await this.userService.checkEmailExist(
        formValues.email
      );
      this.alreadyExistingName = await this.userService.checkNameExist(
        formValues.name
      );

      if (!this.alreadyExistingEmail && !this.alreadyExistingName) {
        this.onboardingData.email = formValues.email;
        this.onboardingData.displayName = formValues.name;
        this.onboardingData.userName = formValues.discordName;
        this.step = this.step + 1;
      }
    } else {
      this.formStep1.markAllAsTouched();
    }
    this.isLoading = false;
  }

  public onClick(): void {
    const allDivs = document.querySelectorAll(
      '.auth-steps-container__step-2__leverages-wrap__item'
    );
    const allRiscs = document.querySelectorAll(
      '.auth-steps-container__step-2__risks-wrap__item'
    );
    const leverage = Array.from(allDivs).find((div) => {
      return div.className.includes('selected');
    });
    const risk = Array.from(allRiscs).find((div) => {
      return div.className.includes('selected');
    });
    if (leverage && risk) {
      this.inValidRiskData = false;
    }
  }

  async signUp(): Promise<void> {
    const allDivs = document.querySelectorAll(
      '.auth-steps-container__step-2__leverages-wrap__item'
    );
    const allRiscs = document.querySelectorAll(
      '.auth-steps-container__step-2__risks-wrap__item'
    );
    const leverage = Array.from(allDivs).find((div) => {
      return div.className.includes('selected');
    });
    const risk = Array.from(allRiscs).find((div) => {
      return div.className.includes('selected');
    });
    if (leverage && risk) {
      this.inValidRiskData = false;
      this.onboardingData.preferredLeverage = leverage.innerHTML;
      this.onboardingData.preferredRiskType = risk.innerHTML;
      this.onboardingData.hasCompletedOnboarding = true;
      this.closeModal.emit(true);
      await this.completeOnboarding();
    }
    this.inValidRiskData = true;
  }

  async completeOnboarding(): Promise<void> {
    this.isLoading = true;
    try {
      this.subscription.add(
        this.userService.updateUser(this.onboardingData).subscribe(() => {
          this.toast.presentToast('Onboarding completed!', 'success');
          this.modalController.dismiss({ data: 'completeOnboarding' });
        })
      );
    } catch (err) {
      console.log(err.message);
    } finally {
      this.isLoading = false;
    }
  }

  public back(): void {
    this.step = this.step - 1;
  }

  public onClickDiv(divElement: HTMLElement): void {
    const allDivs = document.querySelectorAll(
      '.auth-steps-container__step-2__leverages-wrap__item'
    );
    allDivs.forEach((div) => {
      div.classList.remove('selected');
    });
    divElement.classList.add('selected');
  }

  public onClickRisk(divElement: HTMLElement): void {
    const allDivs = document.querySelectorAll(
      '.auth-steps-container__step-2__risks-wrap__item'
    );
    allDivs.forEach((div) => {
      div.classList.remove('selected');
    });
    divElement.classList.add('selected');
  }

  public close(): void {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

interface OnboardingDataModel {
  displayName: string;
  email: string;
  hasCompletedOnboarding: boolean;
  preferredLeverage: string;
  preferredRiskType: string;
  userName: string;
}
