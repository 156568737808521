import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { MISSING_COMPANY_LOGO } from './constants';

export function setCookie(
  name: string,
  value: string,
  secondsToExpire: number,
  refreshToken: string
): void {
  const date = new Date();
  date.setTime(date.getTime() + secondsToExpire * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + '; ' + expires + '; path=/';
  document.cookie =
    AuthService.RefreshTokenCookieName + '=' + refreshToken + ';';
}

export function getCookie(cookieName: string): string | null {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

export function clearCookie(cookieName): void {
  if (cookieName === AuthService.AccessTokenCookieName && getCookie('admin')) {
    document.cookie = 'admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  document.cookie =
    cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function isLong(side: string): boolean {
  return side == 'bto' || side == 'stc';
}

export function validationError(data: any): string {
  let result = '';
  data.error.error.validationErrors.forEach((err) => {
    err.messages.forEach((message) => {
      result += message + '\n';
    });
  });
  return result;
}

export async function getImageUrl(
  title: string,
  ext: string = 'png',
  path: string = 'images/assets',
  defaultUrl: string = MISSING_COMPANY_LOGO
): Promise<string> {
  try {
    const url = `https://cryptocoreprod.blob.core.windows.net/${path}/${title.toLowerCase()}.${ext}`;
    const response = await fetch(url);
    if (response.ok) {
      return url;
    } else {
      return defaultUrl;
    }
  } catch (err) {
    return defaultUrl;
  }
}

export const calculatePeriod = (date: Date) => {
  const currentTime: number = Date.now();
  const activityDateTime = new Date(date);
  const timeDifference = currentTime - activityDateTime.getTime();

  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const hoursDifference = Math.floor(minutesDifference / 60);
  const remainingMinutes = minutesDifference % 60;

  const daysDifference = Math.floor(hoursDifference / 24);
  const remainingHours = hoursDifference % 24;

  const monthsDifference = Math.floor(daysDifference / 30); // Assuming a month is 30 days
  const remainingDays = daysDifference % 30;

  let result = '';
  if (monthsDifference > 0) {
    result += monthsDifference > 0 ? monthsDifference + 'mo ' : '';
    return result;
  } else if (remainingDays > 0) {
    result += remainingDays > 0 ? remainingDays + 'd ' : '';
    return result;
  } else if (remainingHours > 0) {
    result += remainingHours > 0 ? remainingHours + 'h ' : '';
  }
  result += remainingMinutes > 0 ? remainingMinutes + 'mins ' : '';

  return result;
};

export const validUrl: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.value) {
    try {
      const url = new URL(control.value);
      if (url.protocol === 'http:' || url.protocol === 'https:') {
        return null;
      } else {
        return { invalidUrl: true };
      }
    } catch (_) {
      return { invalidUrl: true };
    }
  } else {
    return null;
  }
};

export const noDiscordLinks: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const discordRegex = /discord\.gg/i;
  if (control?.value && discordRegex.test(control.value)) {
    return { invalidContent: true };
  }
  return null;
};
