import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonModal, MenuController } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { UserModel } from '../../../core/models/user/user';
import { AppQuery } from 'src/app/core/state/app/app.query';
import { DashboardConstants } from '../../constants/dashboard.constants';
import { ModalService } from '../modals/modal/modal.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          left: '-280px',
        })
      ),
      state(
        'open',
        style({
          left: '0px',
        })
      ),
      transition('closed => open', [animate('0.25s')]),
      transition('open => closed', [animate('0.25s')]),
    ]),

    trigger('openCloseAppSwitcher', [
      state(
        'closed',
        style({
          width: '28px',
          right: '20px',
        })
      ),
      state(
        'open',
        style({
          width: '210px',
          right: '20px',
        })
      ),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
})
export class BurgerMenuComponent implements OnInit, OnDestroy {
  public user: UserModel;
  public privacyPolicyModal: boolean = false;
  public collapsed = true;
  public dashBoardPage: boolean;

  @Output() isCollapsedBurgerMenu: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild(IonModal) modal: IonModal;

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthService,
    private appQuery: AppQuery,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe(async (user) => {
          this.user = user;
        })
    );
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.dashBoardPage = event.url.includes('dashboard');
        }
      })
    );
    this.dashBoardPage = window.location.href.includes('dashboard');
  }

  async navigate(url: string = ''): Promise<void> {
    this.modalService.close();
    this.closeMenu();
    this.router.navigate([`/${url}`]);
  }

  public onMenuStateChanged(): void {
    this.isCollapsedBurgerMenu.emit(this.collapsed);
  }

  async toDashboardPage(): Promise<void> {
    this.closeMenu();
    if (this.user) {
      this.router.navigate(['/dashboard']);
    } else {
      this.signInWithDiscord();
    }
  }

  async signInWithDiscord(): Promise<void> {
    this.closeMenu();
    try {
      await this.authService.signInDiscord();
    } catch (err) {
      console.log(err);
    }
  }

  async logOut(): Promise<void> {
    this.closeMenu();
    this.authService.logout();
    if (location.href.includes('dashboard')) {
      this.router.navigate(['/home']);
    } else {
      window.location.reload();
    }
  }

  async showPrivacyPolicy(): Promise<void> {
    this.closeMenu();
    this.privacyPolicyModal = true;
  }

  public closeModal(): void {
    this.privacyPolicyModal = false;
  }

  private closeMenu(): void {
    this.collapsed = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
