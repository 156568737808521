import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { SpinnerVideoComponent } from 'src/app/shared/components/spinner-video/spinner-video.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinner: any;
  private videoLoader: any;

  constructor(
    private loadingController: LoadingController,
    private modalCtrl: ModalController
  ) {}

  async show(): Promise<void> {
    const spinner = await this.loadingController.create({
      message: '',
      spinner: 'crescent',
      cssClass: 'loader',
    });
    this.spinner = spinner;
    await this.spinner.present();
  }

  async hide(): Promise<void> {
    await this.spinner?.dismiss();
  }

  public async showVideoLoader(): Promise<void> {
    const videoLoader = await this.modalCtrl.create({
      component: SpinnerVideoComponent,
    });
    this.videoLoader = videoLoader;
    this.videoLoader.present();
  }

  public async hideVideoLoader(): Promise<void> {
    this.videoLoader.dismiss();
  }
}
