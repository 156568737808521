<div class="modal ion-page">
  <div class="inner-content custom-scroll">
    <button class="close-btn" (click)="close()"></button>
    <div class="title">About</div>
    <app-separator
      *ngIf="!isMobile"
      [background]="'rgba(255, 255, 255, 0.10)'"
    ></app-separator>
    <div class="content" *ngIf="user" [formGroup]="form">
      <div class="content-item">
        <div class="content-item-header">
          <img
            src="assets/dashboard/profile/modal-instagram.svg"
            alt="Instagram icon"
          />
          <div class="content-item-header-title">Instagram</div>
        </div>
        <input
          class="content-item-text"
          type="text"
          placeholder="enter Instagram link..."
          formControlName="instagramLink"
        />
        <div class="error-block">
          <div
            class="error"
            *ngIf="
              form.get('instagramLink')?.errors &&
              form.get('instagramLink')?.errors['invalidUrl']
            "
          >
            Not valid Url
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-header">
          <img src="assets/dashboard/profile/modal-twitter.svg" alt="X icon" />
          <div class="content-item-header-title">X</div>
        </div>
        <input
          class="content-item-text"
          type="text"
          placeholder="enter X link..."
          formControlName="twitterLink"
        />
        <div class="error-block">
          <div
            class="error"
            *ngIf="
              form.get('twitterLink')?.errors &&
              form.get('twitterLink')?.errors['invalidUrl']
            "
          >
            Not valid URL
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-header">
          <img
            src="assets/dashboard/profile/modal-web.svg"
            alt="Website icon"
          />
          <div class="content-item-header-title">Website</div>
        </div>
        <input
          class="content-item-text"
          type="text"
          placeholder="enter website link..."
          formControlName="websiteLink"
        />
        <div class="error-block">
          <div
            class="error"
            *ngIf="
              form.get('websiteLink')?.errors &&
              form.get('websiteLink')?.errors['invalidUrl']
            "
          >
            Not valid URL
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-item-header">
          <div class="content-item-header-title">About</div>
        </div>
        <textarea
          class="content-item-text-description"
          type="text"
          placeholder="enter description..."
          formControlName="about"
          maxlength="2000"
        ></textarea>
        <div class="error-block">
          <div class="error" *ngIf="form.get('about')?.errors && form.get('about')?.errors['invalidContent']">
            Error: Content Not Allowed in Bio
          </div>
        </div>
      </div>
    </div>
    <app-separator
      *ngIf="!isMobile"
      [background]="'rgba(255, 255, 255, 0.10)'"
    ></app-separator>
    <div class="buttons">
      <button class="btn cancel-btn" (click)="close()">Cancel</button
      ><button
        class="btn save-btn"
        (click)="edit()"
        [disabled]="form.invalid || !form.dirty"
      >
        Save
      </button>
    </div>
  </div>
</div>

<ng-template #confirmCloseModal>
  <div class="title">
    Entered data will be lost. Are you sure you want to close?
  </div>
  <button (click)="confirmClose()" class="action">Proceed</button>
</ng-template>
