import { Injectable } from '@angular/core';

import { Observable, lastValueFrom } from 'rxjs';

import { PlanModel } from '../models/plans/plans';
import { HttpClientService } from './http-client.service';
import { Plan } from '../models/plans/plans-subscription-types';
import { UserSubscriptionModel } from '../models/plans/user-subscription.model';
import { UpgradePlanModel } from '../models/plans/upgrade-plan.model';
import { UpgradePlanParams } from '../models/plans/upgrade-plan-params';
import { UpgradePlanParamsStripe } from '../models/plans/upgrade-plan-params-stripe';
import { BuyMonthsParamsStripe } from '../models/plans/add-months-param';
import { GlobalDiscountsModel } from '../models/plans/global-discounts.model';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  public plans: PlanModel[] = [
    {
      title: Plan.basic,
      details: [],
      features: [
        {
          value: 'App Dashboard Access',
          popup: {
            title: '',
            data: [
              {
                title: 'App Dashboard Access',
                content: `Receive access to our ever-improving technology dashboard that shows an analytical view of the trades being shares in CT, who's performing well, and your own personal view of your alerts and what trades you have open`,
              },
            ],
          },
          height: 120,
        },
        {
          value: 'Signals Access',
          popup: {
            title: '',
            data: [
              {
                title: 'Signals Access',
                content: `Real-time trading signals executed live from over 100 traders of different skill levels.  Primarily leveraged trading and futures based opportunities`,
              },
            ],
          },
          height: 100,
        },
        {
          value: 'Main Chatroom Access',
          popup: {
            title: '',
            data: [
              {
                title: 'Main Chatroom Access',
                content: `Get access to the main chatting zone where users discuss real-time trading opportunities in Crypto ranging from memecoins to major perpetual futures`,
              },
            ],
          },
          height: 100,
        },
      ],
      featuresList: [
        'App Dashboard Access',
        'Signals Access',
        'Main Chatroom Access',
      ],
    },
    {
      title: Plan.elite,
      details: [],
      features: [
        {
          value: 'All Basic Features',
          popup: {
            title: 'All Basic Features',
            data: [
              {
                title: 'App Dashboard Access',
                content: `Receive access to our ever-improving technology dashboard that shows an analytical view of the trades being shares in CT, who's performing well, and your own personal view of your alerts and what trades you have open`,
              },
              {
                title: 'Signals Access',
                content: `Real-time trading signals executed live from over 100 traders of different skill levels.  Primarily leveraged trading and futures based opportunities`,
              },
              {
                title: 'Main Chatroom Access',
                content: `Get access to the main chatting zone where users discuss real-time trading opportunities in Crypto ranging from memecoins to major perpetual futures`,
              },
            ],
          },
          height: 325,
        },
        {
          value: 'Elite Educational Content',
          popup: {
            title: '',
            data: [
              {
                title: 'Elite Educational Content',
                content: `Access to our educational post and video archive.  Receive content as soon as it's made by our millionaire mentors and top traders`,
              },
            ],
          },
          height: 100,
        },
        {
          value: 'Premium Discord Channels',
          popup: {
            title: '',
            data: [
              {
                title: 'Premium Discord Channels',
                content: `Receive full access to all channels on Discord beyond the main chat and the alerts channels`,
              },
            ],
          },
          height: 82,
        },
        {
          value: 'Stocks + Options Trading Group',
          popup: null,
          height: 82,
        },
      ],
      featuresList: [
        'App Dashboard Access',
        'Signals Access',
        'Main Chatroom Access',
        'Elite Educational Content',
        'Premium Discord Channels',
        'Stocks + Options Trading Group',
      ],
    },
    {
      title: Plan.plus,
      details: [],
      features: [
        {
          value: 'All Elite Features',
          popup: {
            title: 'All Elite Features',
            data: [
              {
                title: 'App Dashboard Access',
                content: `Receive access to our ever-improving technology dashboard that shows an analytical view of the trades being shares in CT, who's performing well, and your own personal view of your alerts and what trades you have open`,
              },
              {
                title: 'Signals Access',
                content: `Real-time trading signals executed live from over 100 traders of different skill levels.  Primarily leveraged trading and futures based opportunities`,
              },
              {
                title: 'Main Chatroom Access',
                content: `Get access to the main chatting zone where users discuss real-time trading opportunities in Crypto ranging from memecoins to major perpetual futures`,
              },
              {
                title: 'Elite Educational Content',
                content: `Access to our educational post and video archive.  Receive content as soon as it's made by our millionaire mentors and top traders`,
              },
              {
                title: 'Premium Discord Channels',
                content: `Receive full access to all channels on Discord beyond the main chat and the alerts channels`,
              },
            ],
          },
          height: 475,
        },
        {
          value: 'Elite Plus Channel',
          popup: {
            title: '',
            data: [
              {
                title: 'Elite Plus Channel',
                content: 'Access the Elite Plus and Higher Only Network',
              },
            ],
          },
          height: 65,
        },
        {
          value: 'Custom Tradingview Scripts',
          popup: {
            title: '',
            data: [
              {
                title: 'Custom Tradingview Scripts',
                content:
                  'Access to our custom made tradingview scripts that are optimized for scalps on the 15-30m Charts and/or Swings on the Daily/Weekly Charts',
              },
            ],
          },
          height: 100,
          color: '#5CC4FF',
        },
        {
          value: 'Algo Alerts',
          popup: {
            title: '',
            data: [
              {
                title: 'Algo Alerts',
                content:
                  'Alert signals that are based upon our hand-set Tradingview Alerts.  Alerts that are based upon or algorithmic settings that alert us when certain discrepancies or parameters are met that allow for an ideal businss environment in the market.',
              },
            ],
          },
          height: 134,
          color: '#FFACAC',
        },
        {
          value: 'Copy Trading Bot',
          popup: null,
          height: 82,
          color: '#FFFFFF',
          borderColor: '#000000',
        },
      ],
      featuresList: [
        'App Dashboard Access',
        'Signals Access',
        'Main Chatroom Access',
        'Elite Educational Content',
        'Premium Discord Channels',
        'Elite Plus Channel',
        'Custom Tradingview Scripts',
        'Algo Alerts',
        'Copy Trading Bot',
      ],
    },
    {
      title: Plan.lifetime,
      details: [],
      features: [],
      featuresList: [
        'Lifetime access to all App features',
        'Main Chat Access',
        'App Access',
        'All Alert Access',
        'Educational Videos and Posts',
        '9+ Extra Channels',
        'Elite Plus and Higher only channel',
        'Copy Trading Bot Access',
        'Thinkorswim and Tradingview Scripts',
        'Algorithmic trading alerts',
        'Full access to Crypto server',
      ],
    },
  ];
  public pauseSubscriptionSurveyQuestions = [
    {
      question: {
        value: 'I’m missing a feature that I want or a feature I use is broke',
        formControlName: 'missingFeature',
      },
      textarea: {
        value: 'what feature is broken or are you missing?',
        formControlName: 'missingFeatureDetails',
      },
    },
    {
      question: {
        value: 'Customer support / service wasn’t good',
        formControlName: 'badCustomerSupport',
      },
      textarea: {
        value: 'what channel or trader are you following? separate with (,)',
        formControlName: 'badCustomerSupportDetails',
      },
    },
    {
      question: {
        value: 'The chatroom’s a mess. Need more moderation',
        formControlName: 'messyChatroom',
      },
    },
    {
      question: {
        value: 'I’m not getting the good trades that I’m looking for',
        formControlName: 'badTrades',
      },
      textarea: {
        value: 'what channel or trader are you following? separate with (,)',
        formControlName: 'badTradesDetails',
      },
    },
    {
      question: {
        value: 'I blew up my account',
        formControlName: 'accuntProblem',
      },
    },
    {
      question: {
        value:
          'I’m busy these days with other things so I’m taking a break, but I’ll be back',
        formControlName: 'tookABreak',
      },
    },
    {
      question: {
        value: 'I don’t have the funds at this time to continue',
        formControlName: 'lowFunds',
      },
    },
    {
      question: {
        value: 'I’m not finding it to be as educative or informative anymore',
        formControlName: 'lowInterest',
      },
      textarea: {
        value: 'type here about your reason',
        formControlName: 'lowInterestDetails',
      },
    },
    {
      question: {
        value:
          'I’d like to see better analysts or traders inside of the channel',
        formControlName: 'weakAnalysts',
      },
    },
    {
      question: {
        value: 'Other',
        formControlName: 'other',
      },
      textarea: {
        value: 'type here about your reason',
        formControlName: 'otherDetails',
      },
    },
  ];

  constructor(private http: HttpClientService) {}

  public async getPlansData(): Promise<PlanModel[]> {
    const plansData = await lastValueFrom(this.http.get(`/Payments/plans`));
    plansData.data.forEach((plan) => {
      const existingPlan = this.plans.find((el) => el.title === plan.plan);
      if (existingPlan) {
        existingPlan.details = plan.details;
      }
    });
    return this.plans;
  }

  public async getUserSubscription(): Promise<UserSubscriptionModel> {
    const userBilling = await lastValueFrom(this.http.get(`/Payments/billing`));
    return userBilling.data;
  }

  public payWithStripe(planId: string): Observable<any> {
    const body = {
      planId: planId,
    };
    return this.http.post('/Payments/stripe/checkout', body);
  }

  public pauseSubscription(pauseReason: string = ''): Observable<{ data: UserSubscriptionModel }> {
    const body = {
      pauseInMonths: 1,
      pauseReasons: [pauseReason],
    };
    return this.http.post('/Payments/pause', body);
  }

  public cancelSubscription(data: string[]): Observable<{ data: UserSubscriptionModel }> {
    const body = {
      cancellationReasons: data,
    };
    return this.http.post('/Payments/cancel', body);
  }

  public async getPlanForUpdate(
    plan: UpgradePlanParams
  ): Promise<UpgradePlanModel> {
    try {
      const response = await lastValueFrom(
        this.http.get<{ data: UpgradePlanModel }>(
          `/Payments/upgrade?Plan=${plan.title}&SubscriptionInterval=${plan.subscriptionInterval}&subscriptionIntervalCount=${plan.subscriptionIntervalCount}`
        )
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  public confirmUpgrade(plan: UpgradePlanParamsStripe): Observable<any> {
    return this.http.post(`/Payments/upgrade/stripe`, plan);
  }

  public buyAdditionalMonthsStripe(
    plan: BuyMonthsParamsStripe
  ): Observable<{ data: string }> {
    return this.http.post(`/Payments/stripe/checkout/months`, plan);
  }

  public async getGlobalDiscounts(): Promise<GlobalDiscountsModel> {
    try {
      const response = await lastValueFrom(
        this.http.get<{ data: GlobalDiscountsModel }>(
          `/usersubscriptions/subscription/discounts/global`
        )
      );
      return response?.data;
    } catch (err) {
      throw err;
    }
  }

  public isPaidSubscription(plan: Plan): boolean {
    const paidUserPlans = [
      Plan.basic,
      Plan.elite,
      Plan.plus,
      Plan.lifetime
    ];
    return (paidUserPlans.includes(plan));
  }
}
