import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header-home/header.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './components/footer/footer.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { HeaderMembershipComponent } from './components/header-membership/header-membership.component';
import { AuthModalComponent } from './components/modals/auth-modal/auth-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ModalComponent } from './components/modals/modal/modal.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu/menu-item/menu-item.component';
import { HoverDirective } from './directives/hover.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiltersModalComponent } from './components/modals/filters-modal/filters-modal.component';
import { UserInfoComponent } from './components/modals/user-info/user-info.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { AppSwitchingModalComponent } from './components/modals/app-switching-modal/switching-modal.component';
import { BuySubscriptionComponent } from './components/modals/buy-subscription/buy-subscription.component';
import { CancelSubscriptionModalComponent } from './components/modals/cancel-subscription/cancel-subscription-modal.component';
import { UpgradeSubscriptionModalComponent } from './components/modals/upgrade-subscription/upgrade-subscription.component';
import { SwitchSubscriptionComponent } from './components/modals/switch-subscription/switch-subscription.component';
import { MobUpgradeSubscriptionComponent } from './components/modals/upgrade-subscription/mob-upgrade-subscription/mob-upgrade-subscription.component';
import { ProfileNoteModalComponent } from './components/modals/profile-note/profile-note-modal.component';
import { DayEndingPipe } from './pipes/day-ending.pipe';
import { EditProfileAboutModalComponent } from './components/modals/edit-profile-about-modal/profile-about-modal.component';
import { ProfileAboutModalMobileComponent } from './components/modals/profile-about-modal-mobile/profile-about-modal-mobile.component';
import { CustomScrollbarDirective } from './directives/custom-scrollbar.directive';
import { WidthMonitorDirective } from './directives/monitor-width.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    OverlayComponent,
    BurgerMenuComponent,
    HeaderMembershipComponent,
    AuthModalComponent,
    PrivacyPolicyComponent,
    SpinnerComponent,
    ModalComponent,
    MenuComponent,
    MenuItemComponent,
    HoverDirective,
    FiltersModalComponent,
    UserInfoComponent,
    SeparatorComponent,
    AppSwitchingModalComponent,
    BuySubscriptionComponent,
    CancelSubscriptionModalComponent,
    UpgradeSubscriptionModalComponent,
    SwitchSubscriptionComponent,
    MobUpgradeSubscriptionComponent,
    ProfileNoteModalComponent,
    EditProfileAboutModalComponent,
    ProfileAboutModalMobileComponent,
    DayEndingPipe,
    CustomScrollbarDirective,
    WidthMonitorDirective,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    MatTooltipModule,
    FormsModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    OverlayComponent,
    BurgerMenuComponent,
    HeaderMembershipComponent,
    AuthModalComponent,
    PrivacyPolicyComponent,
    SpinnerComponent,
    ModalComponent,
    MenuComponent,
    MenuItemComponent,
    HoverDirective,
    FiltersModalComponent,
    UserInfoComponent,
    SeparatorComponent,
    AppSwitchingModalComponent,
    BuySubscriptionComponent,
    CancelSubscriptionModalComponent,
    UpgradeSubscriptionModalComponent,
    SwitchSubscriptionComponent,
    ProfileNoteModalComponent,
    EditProfileAboutModalComponent,
    ProfileAboutModalMobileComponent,
    CustomScrollbarDirective,
    WidthMonitorDirective,
  ],
})
export class SharedModule {}
