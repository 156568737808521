<app-overlay
  *ngIf="!collapsed"
  (click)="collapsed = true; onMenuStateChanged()"
  [background]="
    'linear-gradient(180deg, rgba(1, 13, 26, 0.80) 40.38%, rgba(2, 16, 31, 0.20) 100%)'
  "
></app-overlay>
<div
  *ngIf="collapsed"
  class="burger-icon"
  [style.background-color]="collapsed && !dashBoardPage ? '#000000' : ''"
  [style.width]="collapsed && !dashBoardPage ? null : 'fit-content'"
>
  <img
    src="assets/burger.svg"
    alt="Burger Menu Icon"
    (click)="collapsed = false; onMenuStateChanged()"
  />
</div>

<div class="container" [@openClose]="collapsed ? 'closed' : 'open'">
  <div class="burger-wrap">
    <div class="burger-menu">
      <div class="burger-menu_block">
        <img
          src="/assets/logo-sidebar.png"
          alt="logo"
          class="burger-menu_block__logo"
        />
        <a
          class="burger-menu_block__link burger-menu_block__link-home"
          (click)="navigate()"
          >Home</a
        >
        <a class="burger-menu_block__link" (click)="navigate('trade')"
          >Trading Apps</a
        >
        <a class="burger-menu_block__link" (click)="navigate('copytrading')"
          >Copy Trading</a
        >
        <a class="burger-menu_block__link" (click)="toDashboardPage()"
          >Dashboard</a
        >
      </div>
      <div class="burger-menu_block">
        <div class="burger-menu_block__membership-btn_background">
          <button
            class="burger-menu_block__membership-btn"
            (click)="navigate('membership')"
          >
            <span>Membership</span
            ><span><img src="/assets/arrow-green.png" alt="arrow" /></span>
          </button>
        </div>
        <button
          class="burger-menu_block__login-btn"
          (click)="logOut()"
          *ngIf="user"
        >
          <span>Logout</span
          ><span><img src="/assets/arrow-blue.svg" alt="arrow" /></span>
        </button>
        <button
          class="burger-menu_block__login-btn"
          (click)="signInWithDiscord()"
          *ngIf="!user"
        >
          <span>Login</span
          ><span><img src="/assets/arrow-blue.svg" alt="arrow" /></span>
        </button>
        <div class="burger-menu_block__bottom-links">
          <a (click)="showPrivacyPolicy()">Privacy Policy</a>
          <!-- <a href="#">Refund Policy</a>
        <a href="#">Terms & Conditions</a> -->
        </div>

        <div class="burger-menu_block__copyright">
          © Copyright 2023 - CryptoTraders.<br />All rights reserved.
        </div>
      </div>
    </div>
  </div>
</div>

<app-privacy-policy
  *ngIf="privacyPolicyModal"
  (closePolicy)="closeModal()"
></app-privacy-policy>
