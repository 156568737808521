import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { UserData, UserModel } from '../../models/user/user';
import { CumulativeAlertModel } from '../../models/alerts/cumulative-alerts';
import { TraderModel } from '../../models/trader/trader';
import { AverageTraderModel } from '../../models/trader/trader-avarage';
import { PlanModel } from '../../models/plans/plans';
import { UserSubscriptionModel } from '../../models/plans/user-subscription.model';
import { GlobalDiscountsModel } from '../../models/plans/global-discounts.model';
import { NotesCycleModel } from '../../models/trader/notes-сycles';
import { AlertModel } from '../../models/alerts/alert';
import { TickerModel } from '../../models/ticker';

export interface AppState {
  token: string;
  currentUser: UserModel;
  isAdmin: boolean;
  isAdminMode: boolean;
  userActivityScore: number;
  userEarnings: number;
  activeTickersLoaded: boolean;
  rankedTraders: TraderModel[];
  rankedTradersLoaded: boolean;
  cumulativeAlertsLoaded: boolean;
  topAlertsLoaded: boolean;
  spotlightsLoaded: boolean;
  cumulativeAlerts2DaysPeriod: CumulativeAlertModel;
  cumulativeAlerts7DaysPeriod: CumulativeAlertModel;
  cumulativeAlerts30DaysPeriod: CumulativeAlertModel;
  averageTraderStats: AverageTraderModel;
  plans: PlanModel[];
  userSubscription: UserSubscriptionModel;
  globalDiscounts: GlobalDiscountsModel;
  traderNotes: any;
  notesCycles: NotesCycleModel[];
  sideMenuCollapsed: boolean;
  topAlerts: AlertModel[];
  bestTrades: AlertModel[];
  topAlertsTotal: number;
  activeTickers: TickerModel[];
  hottestTraders: UserData[];
}

const initialState: AppState = {
  token: null,
  currentUser: null,
  isAdmin: false,
  isAdminMode: false,
  userActivityScore: null,
  userEarnings: null,
  activeTickersLoaded: null,
  topAlertsLoaded: null,
  spotlightsLoaded: null,
  rankedTraders: null,
  rankedTradersLoaded: null,
  cumulativeAlertsLoaded: null,
  cumulativeAlerts2DaysPeriod: null,
  cumulativeAlerts7DaysPeriod: null,
  cumulativeAlerts30DaysPeriod: null,
  averageTraderStats: null,
  plans: null,
  userSubscription: null,
  globalDiscounts: null,
  traderNotes: null,
  notesCycles: null,
  sideMenuCollapsed: true,
  topAlerts: null,
  bestTrades: null,
  topAlertsTotal: null,
  activeTickers: null,
  hottestTraders: null,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
  constructor() {
    super(initialState);
  }
}
