import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription, combineLatest, filter } from 'rxjs';

import { AuthModalComponent } from './shared/components/modals/auth-modal/auth-modal.component';
import { ModalService } from './shared/components/modals/modal/modal.service';
import { AppQuery } from './core/state/app/app.query';
import { UserModel } from './core/models/user/user';
import { IntercomService } from './core/services/intercom.service';
import { AuthService } from './core/services/auth.service';
import { ScreenService } from './core/services/screen.service';
import { SignalRService } from './core/services/signal-r.service';

declare global {
  interface Window {
    Intercom: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public hiddenBurgerMenu: boolean;
  public isMobile: boolean;
  private modal: HTMLIonModalElement = null;
  private user: UserModel;
  @ViewChild('confirmOnboarding', { static: false })
  private modalCompleteOnboarding: TemplateRef<any>;
  private subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private modalController: ModalController,
    private modalService: ModalService,
    private router: Router,
    private appQuery: AppQuery,
    private intercomService: IntercomService,
    private screenService: ScreenService,
    private signalRService: SignalRService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isMobile = this.screenService.isMobileView;
    this.subscription.add(
      this.screenService.$isMobileSubject.subscribe((data: boolean) => {
        this.isMobile = data;
      })
    );
    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ),
      this.appQuery.select((x) => x.currentUser),
    ]).subscribe(async ([event, user]: [NavigationEnd, any]) => {
      if (event.url === '/') {
        this.intercomService.initIntercom(user);
      } else {
        this.intercomService.shutdown();
      }
      this.hiddenBurgerMenu =
        event.url.includes('dashboard') || event.url.includes('profile');
    });
    this.subscription.add(
      this.appQuery
        .select((x) => x.currentUser)
        .subscribe(async (user) => {
          if (user) {
            this.user = user;
            if (!this.user.data.hasCompletedOnboarding && !this.syncPage()) {
              await this.openOnboardingModal();
            }
            this.signalRService.initSignalR();
          }
        })
    );
    this.authService.initUser();
    setTimeout(() => {
      if (!this.user) {
        this.intercomService.show();
      }
    }, 30000);
    this.hiddenBurgerMenu =
      window.location.href.includes('dashboard') ||
      window.location.href.includes('profile');
  }

  async openOnboardingModal(): Promise<void> {
    this.modal = await this.modalController.create({
      component: AuthModalComponent,
      componentProps: {
        componentProps: {
          modalInstance: this.modal,
          canDismiss: true,
        },
      },
    });
    this.modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned?.data?.data === 'completeOnboarding') {
        this.modalService.notify(this.modalCompleteOnboarding, 370, 368);
      }
    });
    await this.modal.present();
  }

  public proceedModal(): void {
    this.modalService.close();
    this.router.navigate(['/membership']);
  }

  public onAppWidthMonitorChange(data) {
    const mobileBreakpoint = 1000;
    if (data && data <= mobileBreakpoint) {
      this.screenService.isMobileView = true;
    } else {
      this.screenService.isMobileView = false;
    }
    this.screenService.$isMobileSubject.next(this.screenService.isMobileView);
  }

  private syncPage(): boolean {
    return window.location.href.includes('sync');
  }

  ngOnDestroy(): void {
    this.intercomService.shutdown();
    this.subscription.unsubscribe();
  }
}
